
 .load-container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.corners {
	width: 60px;
	height: 60px;
	position: relative;
	transform-origin: center;
	animation: spin 3s infinite linear;
}

.corner {
	width: 100%;
	height: 100%;
	position: absolute;
	
	&::before {
		display: block;
		width: 48%;
		height: 48%;
		border-radius: 0 40% 0 40%;
		background-color: #1d946f;
		content: '';
	}
}

@keyframes spin1 {
	0% { transform: rotate(0deg); }
	30% { transform: rotate(0deg); }
	70% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes spin2 {
	0% { transform: rotate(0deg); }
	30% { transform: rotate(270deg); }
	70% { transform: rotate(270deg); }
	100% { transform: rotate(360deg); }
}

@keyframes spin3 {
	0% { transform: rotate(0deg); }
	30% { transform: rotate(180deg); }
	70% { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}

@keyframes spin4 {
	0% { transform: rotate(0deg); }
	30% { transform: rotate(90deg); }
	70% { transform: rotate(90deg); }
	100% { transform: rotate(360deg); }
}

.corner--1 {
	animation: spin1 3s infinite cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.corner--2 {
	animation: spin2 3s infinite cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.corner--3 {
	animation: spin3 3s infinite cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.corner--4 {
	animation: spin4 3s infinite cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Ensure it's on top of other elements */
	/* opacity: 0; Initially hidden */
	transition: opacity 0.3s ease-in-out; 
  }
  
  .loader-overlay.active {
	opacity: 1; /* Show the overlay when isLoading is true */
  }
  
