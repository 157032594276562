.leaf-border {
    position: relative;
    width: 100%;  /* Adjust width as needed */
    height: 50px; /* Adjust height to control the space above the leaf */
    border-bottom: 3px solid #4CAF50; /* Green border */
  }
  
  .leaf-border::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Positioning the leaf slightly below the border */
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    background-color: #4CAF50; /* Green color for the leaf */
    clip-path: ellipse(50% 40% at 50% 50%);
  }
  