/* General container styling */
.profile-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Layout styling */
  .profile-layout {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .content-area {
    flex: 1;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Side navigation styling */
  .side-navigation {
    width: 250px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  
  .side-navigation h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
  }
  
  .side-navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .side-navigation ul li {
    padding: 10px 15px;
    cursor: pointer;
    color: #555;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .side-navigation ul li:hover {
    background: #007bff;
    color: #fff;
  }
  
  /* Form styling */
  .profile-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }
  
  .profile-form input,
  .profile-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profile-form button {
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .profile-form button:hover {
    background: #0056b3;
  }
  
  /* Orders table styling */
  .orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .orders-table th, .orders-table td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .orders-table th {
    background: #007bff;
    color: #fff;
  }
  
  .orders-table tr:nth-child(even) {
    background: #f9f9f9;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .profile-layout {
      flex-direction: column;
    }
  
    .side-navigation {
      width: 100%;
      margin-bottom: 20px;
    }

    .display-profile-layout{
      display: block;
    }

    .hide-profile-layout{
      display: none;
    }

    .flex-row{
      display: flex;
      justify-content: space-between;
    }

    .floatr{
      float: right;
    }

    .showhide{
      display: block!important;
    }
  }

  .showhide{
    display: none;
  }



  /* .hide-profile-layout{
    display: none;
  } */

  .updateProfileButton{
    text-align: right;
  }

  .borderBottom{
    border-bottom: 1px solid darkgray;
  }
  