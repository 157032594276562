.product-description-container {
  display: flex;
  flex-direction: row;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image img {
  max-width: 300px;
  border-radius: 10px;
}

.product-details {
  margin-left: 20px;
  flex-grow: 1;
}

.product-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.product-category {
  font-size: 16px;
  color: #777;
  margin-bottom: 10px;
}

.product-price {
  font-size: 20px;
  color: #4caf50;
  font-weight: bold;
  margin-bottom: 15px;
}

.product-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.product-attributes h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.product-attributes ul {
  list-style-type: none;
  padding: 0;
}

.product-attributes li {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
}

.add-to-cart-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #45a049;
}

.special-price-text {
  font-weight: 500;
  font-size: 14px;
  color: #388e3c;
  margin-top: 0px;
}

.price-container {
  margin-top: -10px;
}

.offer-price {
  font-size: 28px;
  font-weight: bold;
}

.actual-price {
  font-size: 16px;
  margin-left: 5px;
  color: #878787;
}

.off-percentage {
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  color: #388e3c;
}

.product-name {
  font-size: 26px !important;
  @media (min-width: 768px) {
    font-size: 32px !important;
  }
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.prod-description {
  margin-top: -5px;
  color: #555555;
}
 
.button-container {
  margin-top: 10px;
}

.gd-button {
  margin-right: 10px !important;
  margin-top: 10px !important;
}

.cust-text {
  color: #363737; 
  margin-bottom: 0px; 
  font-weight: 600;
  font-size: 18px;
}