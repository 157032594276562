/* Navigation Container */
.navigation {
    background-color: #2c3e50;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
  }
  
  /* Navigation Links */
  .nav-links {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .nav-links li a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
  }
  
  .nav-links li a:hover {
    color: #1abc9c;
  }
  
  /* Active Link */
  .active-link {
    color: #1abc9c;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .navigation {
      flex-direction: column;
    }
    .nav-links {
      flex-direction: column;
      gap: 10px;
    }
  }
  