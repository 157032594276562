/* CheckUser.css */
.check-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .check-user-container h2 {
    margin-bottom: 10px;
  }
  
  /* .input-group {
    margin-bottom: 10px;
  }
  
  .input-group input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  } */
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  

  .phone-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    margin-bottom: 35px;
  }
  
  .country-code {
    background-color: #f3f3f3;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid #ccc;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 16px;
  }