/* Styling for the Easy Return Policy component */
.easy-return-policy-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .easy-return-policy-container h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .easy-return-policy-container p {
    color: #555;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .policy-steps {
    margin-top: 20px;
  }
  
  .policy-steps h3 {
    font-size: 20px;
    color: #333;
  }
  
  .policy-steps ol {
    padding-left: 20px;
    font-size: 16px;
    color: #555;
  }
  
  .policy-steps li {
    margin-bottom: 10px;
  }
  
  .important-notice {
    margin-top: 20px;
    background-color: #ffeeba;
    padding: 15px;
    border-radius: 8px;
  }
  
  .important-notice h3 {
    font-size: 20px;
    color: #d9534f;
  }
  
  .important-notice ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 16px;
    color: #555;
  }
  
  .important-notice li {
    margin-bottom: 8px;
  }
  