/* Layout Container */
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.header {
  position: sticky;
  top: 0;
  background-color: #013927;
  color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.header-actions {
  display: flex;
  align-items: center;
}

.menu-toggle {
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  display: none; /* Hidden by default, shown in mobile view */
}

.profile-section {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
}

.profile-name {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: 500;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Mobile View */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #34495e;
    padding: 10px;
    position: absolute;
    top: 60px;
    right: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .nav-links.open { 
    display: flex;
  }

  .profile-name {
    display: none; /* Hide name in mobile view */
  }
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
}

/* Footer */
.footer {
  background-color: #4fcc6b;
  color: white;
  text-align: center;
  padding: 10px 20px;
  font-size: 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.footer-fssai{
  width: 150px;
}

.footer-Jaivik-Bharat-logo{
  width: 84px;
  /* margin-bottom: 10px; */

}
.footer-share-food{
  width: 100px;
}

.footer-fqrcode{
  width:67px;
  margin-right: 10px;
}