body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Main Product Section */
.product-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Sticky Header */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #2e7d32;
  color: white;
  padding: 16px;
  text-align: center;
  font-size: 1.5rem;
  z-index: 1000;
}


/* Product List Styling */
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

/* Individual Product Card */
.product-card {
  width: 100%;
  max-width: 320px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-wrapper {
  background-color: #eaf7e9;
  padding: 16px;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-details {
  padding: 16px;
  text-align: center;
}

.product-name {
  font-size: 1.25rem;
  color: #2e7d32;
  margin: 8px 0;
}

.product-price {
  font-size: 1rem;
  color: #388e3c;
  font-weight: bold;
  margin: 4px 0;
}

.product-description {
  font-size: 0.9rem;
  color: #666;
  margin: 12px 0;
}

.add-to-cart-btn {
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #388e3c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #2e7d32;
}


/* CUSTOM CSS FOR COMMON STYLE */

.hl05eU {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.yRaY8j {
  margin-left: 10px;
}

.yRaY8k {
  margin-left: 10px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #388e3c;

}

.textdec {
  text-decoration: line-through;
  font-size: 16px;
  margin-left: 12px;
  vertical-align: middle;
  color: #878787;
}

.CxhGGd {
  font-size: 28px;
  vertical-align: sub;
  font-size: 1.2rem;
  font-weight: bold;
  color: #28a745;


  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}


.about-container{
  padding: 32px;
    text-align: justify;
    letter-spacing: 0.1px;
    line-height: 1.8;
}

.contact-info{
  padding-top: 48px;
  text-align: center;
}

.aboutlogo{
  height: 100px !important;
    width: 100px !important;
}

.about-title{
  text-align: left!important;
}

.lineHeight{
  display: block;/* or inline-block */
text-overflow: ellipsis;
word-wrap: break-word;
overflow: hidden;
max-height: 3.6em;
line-height: 1.8em;
}

.tdecoration{
  text-decoration: line-through;
}